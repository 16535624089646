import React, {
  useState, useEffect, useLayoutEffect, useRef,
} from 'react';
import { graphql, navigate, Link as GLink } from 'gatsby';
import { gql } from '@apollo/client';
import {
  Box, Text, Flex, Button, Input, Heading, Select, Circle, Divider, Stack, Link,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Content from '../components/content';
import AutoComplete from '../components/AutoComplete';
import Feedback from '../components/Feedback';
import AsideBox from '../components/AsideBox';

import {
  mapDataToVariants, defaults, ping, randomizeOrder,
} from '../util';
import { defaultStyles, workAheadStyles, growthBookStyles } from '../styles';

const styles = defaults(defaultStyles, workAheadStyles);

const PageCalculator = ({
  data, params, location, pageContext,
}) => {
  const { variantName, route } = pageContext;
  const variants = mapDataToVariants(data.allAirtable.nodes, {
    description: 'description',
    title: 'title',
    heading: 'heading',
    subheading: 'subheading',
    instruction: 'instruction',
    occupationLabel: 'occupationLabel',
    occupationPlaceholder: 'occupationPlaceholder',
    industryLabel: 'industryLabel',
    industryPlaceholder: 'industryPlaceholder',
    locationLabel: 'locationLabel',
    locationPlaceholder: 'locationPlaceholder',
    locationSubText: 'locationSubText',
  });
  const content = defaults(variants.default, variants[variantName]);

  const [occ, setOcc] = useState(null);
  const [ind, setInd] = useState(null);
  const [loc, setLoc] = useState('');
  const GET_JOB_TITLE = gql`
    query JobTitle($search: String!) {
      jobTitle(search: $search) {
        name
        occupationCode
        topIndustries{
          industryTitle
          industryCode
        }
      }
    }
`;
  const parseJobTitleResults = jobData => jobData.jobTitle;

  const layoutRef = useRef();
  const industries = useRef(null);

  useEffect(ping, []);

  useEffect(() => {
    if (occ) {
      if (occ.topIndustries) {
        industries.current = occ.topIndustries;
        setInd(industries.current[0]);
      } else {
        industries.current = null;
        setInd(null);
      }
    }
    if (occ && occ.topIndustries) {
      industries.current = occ.topIndustries;
      setInd(industries.current[0]);
    }
  }, [occ]);

  const zipCodeChange = e => {
    const newVal = e.currentTarget.value.replace(/[^0-9]/gi, '');
    setLoc(newVal);
  };

  const selectIndustry = e => {
    const { value, selectedIndex } = e.currentTarget;
    const { text } = e.currentTarget[selectedIndex];
    // console.log(text);
    setInd({
      industryCode: value,
      industryTitle: text,
    });
  };

  const submit = () => {
    const { occupationCode, name } = occ;
    const { industryCode } = ind;
    const zipCode = loc;
    const url = `${route}/results?occ=${occupationCode}&ind=${industryCode}&loc=${zipCode}`;
    navigate(url, {
      state: {
        occupationTitle: name, occupationCode, industryCode, zipCode,
      },
    });
  };

  const ready = (!occ || !ind || !/[0-9]{5}/.test(loc));

  return (
    <Layout title={content.title} description={content.description} location={location} ref={layoutRef}>
      <Heading as="h1" size="3xl" fontWeight="100" mb="10">{content.heading}</Heading>
      <Box fontSize="xl">
        <Content md text={content.subheading} />
      </Box>
      <Divider my="10" />
      <Box mb="10">
        <Content md text={content.instruction} />
      </Box>
      <Box bg="white" py="8" sx={{ ...workAheadStyles.fullWidthBackground }}>
        <Flex sx={styles.inputs} direction={{ base: 'column', md: 'row' }}>
          <Box sx={styles.input}>
            <Heading size="md" mb="4">{content.occupationLabel}</Heading>
            <AutoComplete
              query={GET_JOB_TITLE}
              queryVariableName="search"
              suggestionDisplayField="name"
              suggestionResultCallback={parseJobTitleResults}
              result={occ}
              setResult={setOcc}
              placeholder={content.occupationPlaceholder}
            />
          </Box>
          <Box sx={styles.input}>
            <Heading size="md" mb="4">{content.industryLabel}</Heading>
            {(industries.current && industries.current.length)
              ? <Select variant="flushed" size="lg" _placeholder={{ color: 'gray.700' }} onChange={selectIndustry}> { industries.current.map(industry => <option key={industry.industryCode} value={industry.industryCode}>{industry.industryTitle}</option>) } </Select>
              : <Text as="p">{content.industryPlaceholder}</Text>}
          </Box>
          <Box sx={styles.input}>
            <Heading size="md" mb="4">{content.locationLabel}</Heading>
            <Input variant="flushed" size="lg" placeholder={content.locationPlaceholder} _placeholder={{ color: 'gray.700' }} onChange={zipCodeChange} value={loc} maxLength="5" />
            <Text fontSize="sm">{content.locationSubText}</Text>
          </Box>
          <Box sx={styles.submitButton}>
            <Button w="100%" size="lg" onClick={submit} disabled={ready} colorScheme="orange">See Results</Button>
          </Box>
        </Flex>
      </Box>

      <Box py="8" bg="white" mt="16" sx={{ ...workAheadStyles.fullWidthBackground }}>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">Want to see some examples?</Heading>
        <Flex
          direction={{ base: 'column', md: 'row' }}
        >
          <Button mr={{ base: 0, md: 2 }} mb={{ base: 2, md: 0 }} variant="outline" as={GLink} to={`${route}/results?occ=15-1257&ind=541500&loc=94035`}>A tech worker in Silicon Valley</Button>
          <Button mr={{ base: 0, md: 2 }} mb={{ base: 2, md: 0 }} variant="outline" as={GLink} to={`${route}/results?occ=25-2021&ind=611100&loc=35203`}>A school teacher in Birmingham, AL</Button>
          <Button mr={{ base: 0, md: 2 }} mb={{ base: 2, md: 0 }} variant="outline" as={GLink} to={`${route}/results?occ=35-2014&ind=722500&loc=10012`}>A line cook in New York, NY</Button>
        </Flex>
      </Box>

      <AsideBox>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">Where does this data come from?</Heading>
        <Stack>
          <Text>Core Jobs and Labor data (Wages, Occupation Projections, Job Density (Location Quotient), Industry, Occupation, and Location Mapping) is from the Bureau of Labor Statistics. All of this data is publicly available and can be found on their website.</Text>
          <Text>Cost of Living data is from the Economic Policy Institute's Family Budget Calculator. The estimates were assembled to reflect a "modest yet adequate standard of living". All estimates currently available through this site reflect the cost of living for a family of 4, including 2 adults and 2 children.</Text>
          <Text>Content pertaining to Risk of Automation is from the McKinsey Global Institute as published in a report entitled "Human + machine: A new era of automation in manufacturing". While some data from this report has been approximated, Work ahead believes it to be accurate enough to provide initial guidance and meaningfully inform further research by consumers. </Text>
          <Text>Remote Work Friendliness and Job Flexibility data is from Flexjobs. The data is accumulated from an analysis of Job Postings and includes full time, part-time, employee and freelance roles for remote and flexible arrangement positions. </Text>
        </Stack>
      </AsideBox>
      <AsideBox>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">What is not included in this data?</Heading>
        <Stack>
          <Text>Education level and Job Skills data is not yet accounted for within this data set of the recommendations provided. Some data is in included on the education and experience levels required to get entry level jobs by occupation as provided by the Bureau of Labor Statistics. The data does not yet reflect changing requirements throughout an individuals continuing professional career.</Text>
          <Text>We believe this data is vital for consideration in properly informed career decisions and are working towards to meaningful integration of this information."</Text>
        </Stack>
      </AsideBox>
      <AsideBox>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">How is this tool different from other resources out there?</Heading>
        <Stack>
          <Text>We believe in providing a simple holistic view of the options available in your professional life. While other resource specialize in one or a few of these areas, Work ahead provides a broad view across many potential influencing factors and translates them into easy to understand consumer language. We believe that everyone should be able to make career decisions with confidence and the first step is an informed perspective. </Text>
          <Text>Or said more simply, is equal access to information, catered to your needs.</Text>
        </Stack>
      </AsideBox>
    </Layout>
  );
};

export default PageCalculator;

export const pageQuery = graphql`
  query CalculatorPageQuery(
    $pageName: String!
  ){
    allAirtable(filter: {table: {eq: $pageName}}) {
      nodes {
        data {
          description
          title
          heading
          subheading
          instruction
          occupationLabel
          occupationPlaceholder
          industryLabel
          industryPlaceholder
          locationLabel
          locationPlaceholder
          locationSubText
          variant {
            data {
              name
            }
          }
        }
      }
    }
  }
`;
