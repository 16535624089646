import React, { useRef, useState } from 'react';
import {
  Stack,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  PopoverTrigger,
  PopoverContent,
  Portal,
  PopoverCloseButton,
  ButtonGroup,
} from '@chakra-ui/react';

import { logEvent } from '../util';

const Feedback = ({ label, sx }) => {
  // const debounceRequery = useRef(debounce(requery, 500)).current;

  const feedbackTypes = {
    DOWN: 'Thumbs Up',
    QUESTION: 'Question Mark',
    UP: 'Thumbs Down',
  };

  const sendFeedback = feedbackType => {
    logEvent('Feedback', label, feedbackType);
  };
  return (
    <Popover>
      <PopoverTrigger>
        <Button sx={{ ...sx }} variant="outline" size="md" p="2">📢 Help</Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto">
          <PopoverArrow />
          <PopoverHeader p="1" textAlign="center">Is this helpful to you?</PopoverHeader>
          <PopoverBody p="1">
            <ButtonGroup size="md" isAttached variant="outline">
              <Button variant="ghost" onClick={() => sendFeedback(feedbackTypes.DOWN)}>👎 No</Button>
              <Button variant="ghost" onClick={() => sendFeedback(feedbackTypes.QUESTION)}>❓ I don't know</Button>
              <Button variant="ghost" onClick={() => sendFeedback(feedbackTypes.UP)}>👍 Yes</Button>
            </ButtonGroup>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default Feedback;
